import axios from 'axios'

const host = process.env.VUE_APP_API_HOST || 'https://api-stg-middleware.lionparcel.com'

export default axios.create({
  baseURL: `${host}/api/internal`,
  headers: {
    'Content-type': 'application/json',
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  },
})
